import { HLink } from "../UI/ScrollLink";
import ctaStyle from "./index.module.scss";
function CompanyCta() {
  return (
    <section className="cta_section">
      <div className={`${ctaStyle["cta-box"]} container`}>
        <div className={`${ctaStyle["cta-box__text"]}`}>
          <p className={`${ctaStyle["heading-banner"]} heading-banner`}>
            Are you ready to invest?
          </p>
        </div>
        <div className={`${ctaStyle["cta-box__cta"]}`}>
          <HLink
            href={process.env.REACT_APP_INVESTMENT_OPPORTUNITY_LINK}
            target="_blank"
            className={`${ctaStyle["cta-box__link"]}`}
            btnclasses="btn btn--primary"
          >
            Click here
          </HLink>
        </div>
      </div>
    </section>
  );
}
export default CompanyCta;
