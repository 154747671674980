import List from "../UI/List";
import VMCards from "../VMCards";
import glanceStyle from "./index.module.scss";
function CompanyAtGlance() {
  return (
    <section className="glance-section">
      <div className="container">
        <h2 className="heading heading-secondary u-center-text">
          Company At A Glance
        </h2>
        <div className={`${glanceStyle["content-glance"]} u-margin-top-medium`}>
          <div className={`${glanceStyle["content-glance__left"]}`}>
            <div className={`${glanceStyle["content-textblock"]}`}>
              <p className="para">
                <strong>LORGAVENS</strong> is a privately owned commercial real
                estate investment and development firm with active properties
                across New Jersey, Tennessee, Georgia, Illinois, Louisiana,
                Florida, Nevada & California states. Our business investment
                strategy is highly focused on acquiring Value-add &
                Opportunistic Commercial and Residential properties that
                potentially offer significant upside through renovations and
                repositioning, improving tenant mix, controlling costs, and/or
                price variations. Once properties are acquired and renovated or
                developed, we provide all the adjunct services, skills, and
                systems necessary to maintain each property to the highest
                standards of quality, to achieve greater operational
                efficiencies, and realize the highest profit performance.
              </p>
              <p className="para">
                We at <strong>LORGAVENS</strong>, continually commit to monitor
                trends and national economic conditions affecting the real
                estate industry with drivers like population, employment,
                demographics of underlying submarkets. Experienced in quickly
                recognizing opportunities and long-standing relationships with
                capital sources and create positive economic impact and
                long-term value for our investors. Solid track record with
                diversified risk & return spectrum of residential & commercial
                real estate investments that include, Single-Family, Multifamily
                Housing, Student & Senior Housing, Hotels, Flex
                Space/Warehouses, and Renewable Energy Projects (Solar Energy).
              </p>
              <List
                title="LORGAVENS specializes in the below areas of real estate
                investment programs:"
                items={[
                  "Affordable Rental Housing Programs",
                  "EB-5 Visa Investment Program",
                ]}
              ></List>
            </div>
          </div>
          <div className={`${glanceStyle["content-glance__right"]}`}>
            &nbsp;
          </div>
        </div>

        <VMCards></VMCards>
      </div>
    </section>
  );
}

export default CompanyAtGlance;
