import linkStyle from "./index.module.scss";
import { moduleClassMapper } from "../../../helpers";
import { Button, Link } from "react-scroll";

export const SLink = (props) => {
  const classes = props.btnclasses
    ? `${props.className} ${moduleClassMapper(props.btnclasses, linkStyle)}`
    : props.className;
  return (
    <Link {...props} className={classes}>
      {props.children}
    </Link>
  );
};
export const SButton = (props) => {
  const classes = props.btnclasses
    ? `${props.className} ${moduleClassMapper(props.btnclasses, linkStyle)}`
    : props.className;
  return (
    <Button {...props} className={classes}>
      {props.children}
    </Button>
  );
};

export const HLink = (props) => {
  const classes = props.btnclasses
    ? `${props.className} ${moduleClassMapper(props.btnclasses, linkStyle)}`
    : props.className;
  return (
    <a {...props} className={classes}>
      {props.children}
    </a>
  );
};

/**
 * Form Button
 * @param {*} props
 * @returns
 */
export const FButton = (props) => {
  const classes = props.btnclasses
    ? `${props.className} ${moduleClassMapper(props.btnclasses, linkStyle)}`
    : props.className;
  return (
    <button {...props} className={classes}>
      {props.children}
    </button>
  );
};
