import repoStyle from "./index.module.scss";
import sprite from "../../img/sprite.svg";
function CompanyHighlight() {
  return (
    <section className="company-highlight-section">
      <div className={`${repoStyle["reputation"]}`}>
        <figure className={`${repoStyle["reputation__fig"]}`}>
          <svg className={`${repoStyle["reputation__icon"]}`}>
            <use href={`${sprite}#icon-home`}></use>
          </svg>
          <figcaption className={`${repoStyle["reputation__text"]}`}>
            <p className={`${repoStyle["reputation__bold"]}`}>970</p>
            SINGLE FAMILY HOMES
          </figcaption>
        </figure>
        <figure className={`${repoStyle["reputation__fig"]}`}>
          <svg className={`${repoStyle["reputation__icon"]}`}>
            <use href={`${sprite}#icon-leaf`}></use>
          </svg>
          <figcaption className={`${repoStyle["reputation__text"]}`}>
            <p className={`${repoStyle["reputation__bold"]}`}>165MW</p>
            SOLAR POWER GENERATION
          </figcaption>
        </figure>
        <figure className={`${repoStyle["reputation__fig"]}`}>
          <svg className={`${repoStyle["reputation__icon"]}`}>
            <use href={`${sprite}#icon-flow-tree`}></use>
          </svg>
          <figcaption className={`${repoStyle["reputation__text"]}`}>
            <p className={`${repoStyle["reputation__bold"]}`}>1,600</p>
            MULTIFAMILY UNITS
          </figcaption>
        </figure>
        <figure className={`${repoStyle["reputation__fig"]}`}>
          <svg className={`${repoStyle["reputation__icon"]}`}>
            <use href={`${sprite}#icon-map`}></use>
          </svg>
          <figcaption className={`${repoStyle["reputation__text"]}`}>
            <p className={`${repoStyle["reputation__bold"]}`}>200,000 sqft</p>
            INDUSTRIAL WAREHOUSING
          </figcaption>
        </figure>
        <figure className={`${repoStyle["reputation__fig"]}`}>
          <svg className={`${repoStyle["reputation__icon"]}`}>
            <use href={`${sprite}#icon-line-graph`}></use>
          </svg>
          <figcaption className={`${repoStyle["reputation__text"]}`}>
            <p className={`${repoStyle["reputation__bold"]}`}>$461 MILLIONS</p>
            PAST, ON-GOING & PLANNED PROJECTS ESTIMATED VALUE
          </figcaption>
        </figure>
      </div>
    </section>
  );
}
export default CompanyHighlight;
