import footerStyle from "./index.module.scss";
function Footer() {
  return (
    <footer className={footerStyle.footer}>
      <div className={footerStyle.footer__content}>
        <div className={footerStyle.footer__copyright}>
          &copy; 2021 LORGAVENS Propertys LLC or its affiliates. All Rights
          Reserved.
        </div>
      </div>
    </footer>
  );
}
export default Footer;
