import { useRef, useState } from "react";
import { FButton } from "../../ScrollLink";
import formStyle from "../index.module.scss";
import Alert from "react-bootstrap/Alert";
function ContactForm() {
  const nameRef = useRef("");
  const emailRef = useRef("");
  const subjectRef = useRef("");
  const messageRef = useRef("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmit(true);
    setSubmitSuccess(false);
    setSubmitError(false);
    const enquiryBody = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      subject: subjectRef.current.value,
      message: messageRef.current.value,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_LINK + "enquiries",
        {
          method: "POST",
          body: JSON.stringify(enquiryBody),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const message = await response.text();
      nameRef.current.value = "";
      emailRef.current.value = "";
      subjectRef.current.value = "";
      messageRef.current.value = "";
      setSubmitSuccess(true);
    } catch (e) {
      setIsSubmit(false);
      setSubmitError(true);
    }
  };
  return (
    <>
      <form
        action="#"
        className={`${formStyle["form"]}`}
        onSubmit={handleSubmit}
      >
        <div className="u-margin-bottom-medium">
          <h2 className="heading heading-secondary">Get in touch</h2>
        </div>

        <div className={`${formStyle["form__group"]}`}>
          <input
            type="text"
            className={`${formStyle["form__input"]}`}
            placeholder="Full name"
            id="name"
            required
            ref={nameRef}
          />
          <label htmlFor="name" className={`${formStyle["form__label"]}`}>
            Full name
          </label>
        </div>

        <div className={`${formStyle["form__group"]}`}>
          <input
            type="email"
            className={`${formStyle["form__input"]}`}
            placeholder="Email address"
            id="email"
            required
            ref={emailRef}
          />
          <label htmlFor="email" className={`${formStyle["form__label"]}`}>
            Email address
          </label>
        </div>

        <div className={`${formStyle["form__group"]}`}>
          <input
            type="text"
            className={`${formStyle["form__input"]}`}
            placeholder="Subject"
            id="subject"
            required
            ref={subjectRef}
          />
          <label htmlFor="subject" className={`${formStyle["form__label"]}`}>
            Subject
          </label>
        </div>

        <div className={`${formStyle["form__group"]}`}>
          <textarea
            name="message"
            id="message"
            className={`${formStyle["form__input"]}`}
            placeholder="Message"
            cols="30"
            rows="5"
            required
            ref={messageRef}
          ></textarea>
          <label htmlFor="message" className={`${formStyle["form__label"]}`}>
            Message
          </label>
        </div>

        <div className={`${formStyle["form__group"]}`}>
          {!isSubmit && (
            <FButton btnclasses="btn btn--primary" type="submit">
              Submit →
            </FButton>
          )}
        </div>
        {submitSuccess && (
          <Alert variant="success" className="alert-80">
            <Alert.Heading>Thank You</Alert.Heading>
            <p>Thank You for your enquiry!</p>
            <hr />
            <p className="mb-0">We will get back to you soon.</p>
          </Alert>
        )}
        {submitError && (
          <Alert variant="danger" className="alert-80">
            <Alert.Heading>Error</Alert.Heading>
            <p>Something went wrong.</p>
            <hr />
            <p className="mb-0">Please try again after some time.</p>
          </Alert>
        )}
      </form>
    </>
  );
}
export default ContactForm;
