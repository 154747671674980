import sprite from "../../img/sprite.svg";
import vmStyle from "./index.module.scss";
function VMCards() {
  return (
    <div className={`${vmStyle["vm-block"]}`}>
      <figure className={`${vmStyle["vm-block__fig"]}`}>
        <svg className={`${vmStyle["vm-block__icon"]}`}>
          <use href={`${sprite}#icon-eye`}></use>
        </svg>
        <figcaption className={`${vmStyle["vm-block__caption"]} u-center-text`}>
          <h3
            className={`${vmStyle["vm-block__heading"]} heading heading-tertiary`}
          >
            Our Vision
          </h3>
          <p className="para">
            To become one of the most valued business partners in making
            multifamily rentals, assisted living, student & senior housing,
            hotels, and family resorts more accessible, affordable, and
            sustainable to all communities.
          </p>
        </figcaption>
      </figure>

      <figure className={`${vmStyle["vm-block__fig"]}`}>
        <svg className={`${vmStyle["vm-block__icon"]}`}>
          <use href={`${sprite}#icon-network`}></use>
        </svg>
        <figcaption className={`${vmStyle["vm-block__caption"]} u-center-text`}>
          <h3
            className={`${vmStyle["vm-block__heading"]} heading heading-tertiary`}
          >
            Our Mission
          </h3>
          <p className="para">
            LORGAVENS continues to acquire and develop high quality, diverse
            real estate assets based on the realistic values in the targeted
            submarkets generally throughout the Mid-Atlantic, Southeast,
            Southwest, Midwest, Great Plains, and New Jersey metropolitan and
            suburban housing markets across the United States. LORGAVENS has
            been quickly adapted to the diversity of individual investors,
            markets, and residents through sound leadership, unparalleled
            industry expertise, and our unwavering commitment to service.
          </p>
          <p className="para">
            LORGAVENS believes that working hard at something you love to do
            with like-minded people you trust is one of the greatest gifts in
            life. We are not only proud to offer the best investment
            opportunities for our investors, create various construction jobs in
            the local markets, but also take great pleasure in contributing
            towards the expansion of affordable housing rental accommodations by
            partnering with different federal housing agencies.
          </p>
        </figcaption>
      </figure>
    </div>
  );
}
export default VMCards;
