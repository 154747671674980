import "./index.scss";
import video from "../../videos/lorgavens-hero-video.mp4";
function BackgroundVideo() {
  return (
    <div className="bg-video">
      <video className="bg-video__content" autoPlay muted loop>
        <source src={video} type="video/mp4" />
        Your browser is not supported!
      </video>
    </div>
  );
}
export default BackgroundVideo;
