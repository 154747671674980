import "./App.scss";
import BackgroundVideo from "./components/BackgroundVideo";
import CompanyAbout from "./components/CompanyAbout";
import CompanyAtGlance from "./components/CompanyAtGlance";
import CompanyContact from "./components/CompanyContact";
import CompanyCta from "./components/CompanyCta";
import CompanyHighlight from "./components/CompanyHighlight";
import Footer from "./layout/footer";
import Header from "./layout/header";

import "bootstrap/dist/css/bootstrap.min.css";
function App() {
  return (
    <div className="wrapper">
      <BackgroundVideo></BackgroundVideo>
      <Header></Header>
      <CompanyHighlight></CompanyHighlight>
      <CompanyAtGlance></CompanyAtGlance>
      <CompanyAbout></CompanyAbout>
      <CompanyContact></CompanyContact>
      <CompanyCta></CompanyCta>
      <Footer></Footer>
    </div>
  );
}

export default App;
