import listStyle from "./index.module.scss";
function List(props) {
  return (
    <>
      {props.title && <h3 className="heading-list">{props.title}</h3>}
      <ul className={listStyle.list}>
        {props.items.map((val, index) => (
          <li key={index} className={listStyle.list__item}>
            {val}
          </li>
        ))}
      </ul>
    </>
  );
}
export default List;
