import Nav from "../../components/Nav";
import { SLink, HLink } from "../../components/UI/ScrollLink";
import headStyle from "./index.module.scss";
function Header() {
  return (
    <header className="header-section">
      <div className={headStyle.header}>
        <div className={headStyle.header__logo}>
          <img
            src="https://s3.us-east-2.amazonaws.com/lorgaven.prod.media/common/lorgaven_properties_logo_110x65.png"
            alt="Lorgavens"
            className={headStyle.header_img}
          />
        </div>
        <Nav></Nav>
      </div>
      <div className={headStyle.hero}>
        <h1 className="heading heading-primary">
          Make room for a better living with us
        </h1>
        <p className={headStyle.hero__description}>
          We provide services from Property Acquisitions to overall asset
          management.
        </p>
        <div className={headStyle.hero__cta}>
          <SLink
            href="#"
            to="contact-us"
            spy={true}
            smooth={true}
            offset={50}
            duration={500}
            btnclasses="btn btn--white btn--animated"
          >
            Contact Us
          </SLink>
          <HLink
            href={process.env.REACT_APP_INVESTMENT_OPPORTUNITY_LINK}
            target="_blank"
            btnclasses="btn btn--primary btn--animated"
          >
            Get Started
          </HLink>
        </div>
      </div>
    </header>
  );
}
export default Header;
