import sprite from "../../../img/sprite.svg";
function SVGFigure(props) {
  return (
    <figure className={props.figClass}>
      <figcaption className={props.capsClass}>
        <h4 className="heading-banner">Let's discuss everything!</h4>
      </figcaption>
      <svg className={props.svgClass}>
        <use href={`${sprite}#${props.icon}`}></use>
      </svg>
    </figure>
  );
}
export default SVGFigure;
