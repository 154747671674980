import ContactForm from "../UI/Forms/ContactForm";
import SVGFigure from "../UI/SVGFigure";
import contactStyle from "./index.module.scss";
function CompanyContact() {
  return (
    <section className="contact-section" id="contact-us">
      <div className={`${contactStyle["contact"]} container`}>
        <div className={`${contactStyle["contact__form"]}`}>
          <ContactForm></ContactForm>
        </div>

        <div className={`${contactStyle["contact__text"]}`}>
          <SVGFigure
            figClass={`${contactStyle["contact__fig"]}`}
            capsClass={`${contactStyle["contact__caption"]}`}
            svgClass={`${contactStyle["contact__icon"]}`}
            icon="icon-chat"
          ></SVGFigure>
        </div>
      </div>
    </section>
  );
}
export default CompanyContact;
