import navStyle from "./index.module.scss";
import { SLink, HLink } from "../UI/ScrollLink";
function Nav() {
  return (
    <div className={navStyle.navigation}>
      <input
        type="checkbox"
        className={navStyle.navigation__checkbox}
        id="navi-toggle"
      />
      <label htmlFor="navi-toggle" className={navStyle.navigation__button}>
        <span className={navStyle.navigation__icon}>&nbsp;</span>
      </label>

      <div className={navStyle.navigation__background}>&nbsp;</div>

      <nav className={navStyle.navigation__nav}>
        <ul className={navStyle.navigation__nav__list}>
          <li className={navStyle.navigation__nav__listitem}>
            <SLink
              activeClass="active"
              href="#"
              to="about-us"
              spy={true}
              smooth={true}
              offset={50}
              duration={500}
              className={navStyle.navigation__nav__link}
            >
              About Us
            </SLink>
          </li>
          <li className={navStyle.navigation__nav__listitem}>
            <HLink
              href={process.env.REACT_APP_PORTFOLIO_LINK}
              target="_blank"
              className={navStyle.navigation__nav__link}
            >
              Our Portfolio
            </HLink>
          </li>
          <li className={navStyle.navigation__nav__listitem}>
            <HLink
              href={process.env.REACT_APP_INVESTMENT_OPPORTUNITY_LINK}
              target="_blank"
              className={navStyle.navigation__nav__link}
            >
              Investment Opportunites
            </HLink>
          </li>
          <li className={navStyle.navigation__nav__listitem}>
            <HLink
              href={process.env.REACT_APP_EB5_REDIRECT_LINK}
              target="_blank"
              className={navStyle.navigation__nav__link}
            >
              Lorgavens Eb-5
            </HLink>
          </li>
        </ul>
        <div className={navStyle.navigation__nav__cta}>
          <HLink
            href={process.env.REACT_APP_LOGIN_LINK}
            target="_blank"
            btnclasses="btn btn--primary"
          >
            Login
          </HLink>
          <HLink
            href={process.env.REACT_APP_REGISTER_LINK}
            target="_blank"
            btnclasses="btn btn--primary"
          >
            Sign Up
          </HLink>
        </div>
      </nav>
    </div>
  );
}
export default Nav;
