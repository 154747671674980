import List from "../UI/List";

function CompanyAbout() {
  return (
    <section className="about-section" id="about-us">
      <div className="container">
        <h2 className="heading heading-secondary u-center-text">
          About Lorgavens
        </h2>

        <div className="content-about u-margin-top-medium">
          <div className="content-about__why">
            <h3
              className="
            heading heading-tertiary
            u-margin-bottom-small u-margin-top-medium
          "
            >
              Why LORGAVENS ?
            </h3>
            <p className="para">
              The LORGAVENS management team brings more than 25 years of
              professional experience with core commercial real estate &
              investment competencies acquiring high Value-add and Opportunistic
              commercial real estate properties delivering high yields to our
              respective investors.
            </p>
            <p className="para">
              Our Asset Management team makes the end-to-end investment process
              very easy, friendly and profitable by completely educating our
              partners on our due-diligence, business plan approaches and
              different short & long-term exit strategies. Equipped with a keen
              sense of evolving trends, we are able to consistently identify
              opportunities that yield continuous positive passive income flow
              and build wealth in the form of long-term asset equity.
            </p>
            <p className="para">
              Our investment philosophy is “Build Wealth & Grow Together” by
              improving the asset value, increasing investor’s net worth &
              equity returns safely and contributing high quality rental housing
              opportunities to residential communities.
            </p>
          </div>
          <div className="content-about__leadership">
            <h3
              className="
            heading heading-tertiary
            u-margin-bottom-small u-margin-top-medium
          "
            >
              Leadership Team
            </h3>
            <p className="para">
              The founder of LORGAVENS has overall 30 years of entrepreneurship
              expertise in various lines with 20 plus years of dedicated real
              estate experience. The strong pillars of the company were built by
              seasoned real-estate and investment professionals dedicated to
              pave the road for our investors to realize their dreams of
              building long-term wealth and financial future.
            </p>
            <p className="para">
              LORGAVENS management team is built-with highly qualified
              principals with advanced degrees in Management & Technology and
              experience in various executive roles. This previous exposure has
              helped to improve the ultimate performance of the company’s
              residential, commercial, retail, parking, hotels and resort
              investments, leading its acquisitions, financing, leasing,
              operations, and disposition efforts.
            </p>
            <List
              title="LORGAVENS team has in-house subject matter experts in the below
              commercial real estate disciplines:"
              items={[
                "Property Acquisitions",
                "Financial Analysis & Underwriting",
                "Construction Management",
                "Property Management",
                "Overall Asset Management",
              ]}
            ></List>
          </div>
        </div>
      </div>
    </section>
  );
}
export default CompanyAbout;
